import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const FAQs = () => (
  <Layout>
    <SEO title="FAQs" />
    <div class="banner">
      <div class="container">
        <div class="colx1">
          <div class="copy">
            <h3 class="center">
              Frequently Asked Questions
            </h3>
            <a class="btn_alt" href="#"><i class="fa fa-angle-down"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
    <div class="colx1">
      <div class="left">
        <h5 class="q">
        Is your product licensed in Canada?
        </h5>
        <h4>
        Yes this product is licensed as a natural health product by Health Canada.
        </h4>
        <br/>
        <h5 class="q">
        What is the main ingredient?
        </h5>
        <h4>
        Each oral capsule contains 1.5mg of cytisine.
        </h4>
        <br/>
        <h5 class="q">
        How many bottles will I need to quit smoking?
        </h5>
        <h4>
        Each bottle contains the full 25 day treatment.
        </h4>
        <br/>
        <h5 class="q">
        When do I quit smoking?
        </h5>
        <h4>
          You should reduce the number of cigarettes you smoke gradually over the first few days of treatment and quit smoking by the 5th day of treatment.
        </h4>
        <br/>
        <h5 class="q">
        What are the directions?
        </h5>
        <h4>
        The dosing is 1 capsule 6 times daily every 2 hrs with a corresponding reduction of the number of cigarettes smoked for the first 3 days.
        </h4>
        <h4>
        • 4th to 12th day: 1 capsule every 2.5hrs ( 5 capsules daily )
        </h4>
        <h4>
        • 13th to 16th day: 1 capsule every 3hrs ( 4 capsules daily )
        </h4>
        <h4>
        • 17th to 20th day: 1 capsule every 5hrs ( 3 capsules daily )
        </h4>
        <h4>
        • 21st to 25th day: 1 to 2 capsules daily
        </h4>
        <h4>
        Smoking cessation should occur by the 5th day after the initiation of treatment.
        </h4>
        <br/>
        <h5 class="q">
        Are there any side effects?
        </h5>
        <h4>
        The most common side effects from clinical trials were nausea, stomach upset, and sleep disturbances.
        </h4>
        <br/>
        <h5 class="q">
        Where are the bottles manufactured?
        </h5>
        <h4>
        Each bottle is proudly manufactured in Canada and zpharm is a 100% Canadian owned company.
        </h4>
      </div>
    </div>
  </div>
  </Layout>
)

export default FAQs
