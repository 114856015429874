import { Link } from 'gatsby'
import React from 'react'

const Footer = ({ siteTitle }) => (
  <div class="footer">
    <div class="nav">
      <a href="/" class="logo">
        © zpharm, Inc.
      </a>
      <ul class="main">
      <li><Link to="french">Français</Link></li>
      <li><Link to="locator/">Find a Store</Link></li>
       <li><Link to="science/">The Science</Link></li>
       <li><Link to="reviews/">Reviews</Link></li>
       <li><Link to="faqs/">FAQs</Link></li>
       <li><Link to="press/">Press</Link></li>
       <li><Link to="terms/">Terms</Link></li>       
      </ul>
    </div>
  </div>
)

export default Footer
