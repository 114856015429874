import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Header = ({ siteTitle }) => (
  <div class="navbar">
    <div class="nav" >
      <a href="/" class="logo">
        zpharm
      </a>
      <ul class="main" >
      <li><Link to="french">Français</Link></li>
      <li><Link to="locator/">Find a Store</Link></li>
       <li><Link to="science/">The Science</Link></li>
       <li><Link to="reviews/">Reviews</Link></li>
       <li><Link to="faqs/">FAQs</Link></li>
        <li><a class="cta" href="https://zpharm.myshopify.com/cart/25385746313:1?channel=buy_button">Order Now</a></li>
      </ul>
    </div>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
